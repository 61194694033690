import { DidomiSDK } from "@didomi/react"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useThirdPartiesContext } from "../thirdParties.provider"

export default function Didomi ({ children }) {
  const [didomiObject, setDidomiObject] = useState(null)
  const { setGRPDReady } = useThirdPartiesContext()

  return (
    <>
      <DidomiSDK
        apiKey={APP_CONFIG.didomi.apiKey}
        sdkPath={APP_CONFIG.didomi.url}
        iabVersion={2}
        gdprAppliesGlobally
        onReady={didomi => {
          setGRPDReady()
          setDidomiObject(didomi)
        }}
      />
      {children({ clickHandler: didomiObject && didomiObject.preferences.show })}
    </>
  )
}

Didomi.propTypes = {
  children: PropTypes.func.isRequired,
}
