import React from "react"
import Didomi from "../../thirdParties/rgpd/didomi.component"
import { useTranslation } from "../../translation/translate.component"
import styled from "styled-components"

const Pointer = styled.div`
  cursor: pointer;
`

export default function FooterDidomiItem () {
  const { t } = useTranslation()

  return (
    <Didomi>
      {({ clickHandler }) => (
        <Pointer>
          <span data-testid="footer_gestion_cookies" onClick={() => clickHandler()}>
            {t(`common:navigation.footer_cookies_management`)}
          </span>
        </Pointer>
      )}
    </Didomi>
  )
}
