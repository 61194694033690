import React from "react"
import { fontSizes, modules } from "../../structure/theme"
import Link from "../link.component"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useTranslation } from "../../translation/translate.component"
import FooterDidomiItem from "./footerDidomiItem"

const SimplifiedList = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  font-size: ${fontSizes(`small`)};
  color: ${modules(`navigation.footer.color`)};

  ${breakpoint(`small`)`
    flex-direction: row;
  `}
`

export const Copyright = styled.div`
  margin-bottom: 15px;
`

const SimplifiedListItem = styled.li`
  text-align: center;
  padding: 5px 0;

  ${breakpoint(`small`)`
    :not(:last-of-type) :after {
    content: "-";
    padding: 0 5px;
    display: inline-block;
  }
  `}

  a {
    text-decoration: none;
    color: inherit;
  }
`

const cookieProps =
  APP_CONFIG.country === `be`
    ? { route: `politiqueDesCookies` }
    : {
        target: `_blank`,
        rel: `noreferrer`,
        href: `https://www.homeserve.fr/politique-des-cookies?lien_interne=Footer`,
      }

const privateDataProps =
  APP_CONFIG.country === `be`
    ? { route: `politiqueDeDonneesPersonnelles` }
    : {
        target: `_blank`,
        rel: `noreferrer`,
        href: `https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=Footer`,
      }

export default function SimplifiedFooter () {
  const { t } = useTranslation()

  return (
    <SimplifiedList>
      <SimplifiedListItem>
        <Copyright data-testid="footer_copyright">Ⓒ {APP_CONFIG.copyright}</Copyright>
      </SimplifiedListItem>
      <SimplifiedListItem>
        <Link route="mentionsLegales" data-testid="footer_mentions_legales">
          {t(`common:navigation.footer_legal`)}
        </Link>
      </SimplifiedListItem>
      <SimplifiedListItem>
        <Link route="conditionsJuridiques" data-testid="footer_conditions_juridiques">
          {t(`common:navigation.footer_juridic`)}
        </Link>
      </SimplifiedListItem>
      <SimplifiedListItem>
        <Link {...privateDataProps} data-testid="footer_donnees_personnelles">
          {t(`common:navigation.footer_private_data`)}
        </Link>
      </SimplifiedListItem>
      <SimplifiedListItem>
        <Link {...cookieProps} data-testid="footer_politique_des_cookies">
          {t(`common:navigation.footer_cookies`)}
        </Link>
      </SimplifiedListItem>
      <SimplifiedListItem>
        <FooterDidomiItem />
      </SimplifiedListItem>
    </SimplifiedList>
  )
}
